"usecontext";
import React, { useEffect } from "react";
import BGImg from "./assets/vesa-bg.png";
import Logo from "./assets/vesa-logo.svg";
import { BsMedium, BsTwitter, BsDiscord } from "react-icons/bs";
import { Link } from "react-router-dom";

import gsap from "gsap";
import {
  Back,
  Power3,
  Power1,
  Power2,
  Power4,
  Linear,
  Expo,
  Circ,
} from "gsap/dist/gsap";
import { Draggable } from "gsap/Draggable";
import { ScrollTrigger } from "gsap/ScrollTrigger";

const App = () => {
  gsap.registerPlugin(Draggable, ScrollTrigger);

  useEffect(() => {
    let landingAnim = gsap.timeline({
      delay: 1,
    });
    landingAnim

      .fromTo(
        ".vesa-heading h2",
        {
          opacity: 0,
          y: "2.5rem",
        },
        {
          opacity: 1,
          y: 0,
        }
      )
      .fromTo(
        ".boogabera-content .logo",
        {
          opacity: 0,
        },
        {
          opacity: 1,
        },
        "<0"
      )
      .fromTo(
        ".vesa-heading h5",
        {
          opacity: 0,
          y: "1.5rem",
        },
        {
          opacity: 1,
          y: 0,
        }
      )
      .fromTo(
        ".vase-button",
        {
          opacity: 0,
        },
        {
          opacity: 1,
        }
      )
      .fromTo(
        ".social-icons svg",
        {
          opacity: 0,
        },
        {
          opacity: 1,
          stagger: 0.1,
        },
        "<0"
      );
  });

  return (
    <div className="boogabera">
      <main className="boogabera-content">
        <img src={BGImg} alt="" />
        <Link to="/">
          <img src={Logo} alt="" className="logo" />
        </Link>

        <section className="boogabera-center-cont">
          <div className="vesa-heading">
            <h2>PoL Liquid Restaking</h2>
            <h5>Restake Everything</h5>
          </div>
          <div className="vase-button">
            <div className="blur"></div>
            <span>Enter Soon</span>
          </div>
        </section>
      </main>
    </div>
  );
};

export default App;
